<template>
  <div>
    <Newheader></Newheader>
    <div class="flex-col page">
      <div class="flex-col">
        <div class="flex-col section_1">
          <div class="flex-row justify-between section_2" style="opacity: 0;">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016340998638437.png"
              class="image" />
            <div class="flex-row group_1">
              <span class="text">探索车型</span>
              <span class="text_1">KYMCO.LIFE</span>
              <span class="text_2">ECO</span>
              <span class="text_3">品牌</span>
              <span class="text_4">MOVE</span>
              <span class="text_5">联系</span>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016341849358769.png"
                class="image_1" />
            </div>
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016341547046868.png"
              class="image_2" />
          </div>
          <div class="flex-col group_2">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494066013990141.png"
              class="image_3" />
            <div class="flex-col items-start group_3">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494064905110370.png"
                class="image_4" />
              <div class="flex-col items-start image-wrapper">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494066152165439.png"
                  class="image_5" />
              </div>
            </div>
          </div>
          <div class="flex-col group_4">
            <div class="flex-row justify-between section_3">
              <span data-aos="fade-up" class="text_6">KYMCO S7</span>
              <div class="flex-row space-x-31">
                <div data-aos="fade-up" class="flex-col items-center text-wrapper"><span class="text_7" @click="navPath('/drive')" style="cursor:pointer">预约试驾</span>
                </div>
                <div data-aos="fade-up" class="flex-col items-center text-wrapper_1"><a href="https://detail.tmall.com/item.htm?abbucket=6&id=675421454933&rn=ea0280898e20b6115ee7f2229564ad0a&spm=a1z10.3-b-s.w4011-23636442313.80.61f661edT82Rij&sku_properties=8369595:5557472"><span class="text_8" style="cursor:pointer">购买商城</span></a>
                </div>
              </div>
            </div>
            <div class="flex-row justify-between section_4">
              <span class="text_9">产品定位</span>
              <span class="text_10">智动电控核心</span>
              <span class="text_11">智控安全科技</span>
              <span class="text_12">速与幻彩迸现</span>
              <span class="text_13">智驾一骑绝尘</span>
              <span class="text_14">聪敏智联监控</span>
            </div>
          </div>
        </div>

        <div class="flex-col section_5">
          <video id="index_video" class="image_36"
          src="@/assets/video/s7/1.mp4"
          style="object-fit: cover; width: 100%; height: 100%;position: absolute;left: 0;" x5-playsinline="true" webkit-playsinline="true"
          playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true"
          autoplay="" muted="" loop=""></video>
          <div class="flex-col space-y-75 section_6" style="position: relative;z-index:10;">
            <div class="typography-leading-intro">
              <div>
               
                <span class="text_16">
                  <span class="text_15" style="cursor: pointer;">随智 幻动。</span>
                  智享骑趣稳中得力，鲜行一步唯速当道。
                  <br />
                </span>
                <span class="text_17" style="cursor: pointer;">
                  一步内，体验智慧系统互联科技；
                  <br />
                  百里间，驰骋之路乐享驾乘感受。
                </span>
                <span class="text_18" style="cursor: pointer;">智驾领衔当代出行方式，用更速 角逐未来移动局势！</span>
              </div>
              <div @click="play(require('@/assets/video/s7/2.mp4'))" class="flex-col space-y-5 group_7" style="margin-top: 3rem;">
                <span class="text_19 cur">观看视频</span>
                <div class="divider">

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-col items-center space-y-42 section_7">

          <span class="text_20">极致的驾驭体验</span>
          <div class="flex-row justify-center space-x-27 group_8">
            <span class="text_21">强大的动力输出</span>
            <span class="text_22">极致的轻量化设计</span>
            <span class="text_23">完美的热管理系统</span>
          </div>
        </div>
        <div style="height: 5rem;"></div>
        <div data-aos="fade-up" data-aos-offset="300" class="flex-col space-y-31 section_8">
          <div v-if="countshow" class="flex-row justify-between">
            <div class="group_10">

              <countTo class="text_24" :startVal='0' :endVal='3.7' :decimals="1" :duration='5000'></countTo>
              <span class="text_26">s</span>
            </div>
            <div class="group_10">

              <countTo class="text_24" :startVal='0' :endVal='92' :decimals="0" :duration='5000'></countTo>

              <span class="text_28">km/h</span>
            </div>
            <div class="group_11">
              <countTo class="text_29" :startVal='0' :endVal='155' :decimals="0" :duration='5000'></countTo>

              <span class="text_30">km</span>
            </div>
            <div class="group_10 view_2">
              <countTo class="text_24" :startVal='0' :endVal='7.2' :decimals="1" :duration='5000'></countTo>

              <span class="text_32">kw</span>
            </div>
          </div>
          <div class="flex-row justify-between">
            <div class="flex-row space-x-186">
              <span class="text_33">0-50km/h加速</span>
              <span class="text_34">最高时速</span>
            </div>
            <div class="flex-row space-x-228 group_14">
              <span class="text_35">续航力</span>
              <span class="text_36">最大功率</span>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" class="swiper swiper2">
          <div class="flex-col items-start space-y-24 group_15">
                  <span class="text_37">给够动力 就够给力</span>
                  <span class="text_38">由智慧电控核心满擎驱动</span>
                </div>
          <div class="swiper-wrapper">
            <div v-for="t in 3" class="swiper-slide">
              
              <div class="flex-col section_9"
                :style="{ backgroundImage: `url( ${require(`@/assets/images/s7/bg${t}.png`)})` }">
                
                <div class="flex-row justify-between group_16">
                  <div class="flex-col space-y-49 group_17">
                    <div class="flex-col items-start space-y-28">
                      <span class="text_39">{{ list[t - 1].pt }}</span>
                      <span class="text_40">{{ list[t - 1].des }}</span>
                    </div>
                    <div class="flex-row space-x-14">
                      <div class="flex-col group_20">
                        <span class="text_41" v-html="list[t - 1].content"></span>
                        <img
                          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494061662499275.png"
                          class="image_6" v-show="t == 3" />
                        <div v-show="t == 3" class="flex-row space-x-13 group_21">
                          <img
                            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494061773649241.png"
                            class="image_7" />
                          <span class="text_42">125CC 燃油车</span>
                        </div>
                      </div>
                      <span v-show="t == 3" class="text_43">S7</span>
                    </div>
                  </div>
                  <img :src="require(`@/assets/images/s7/${t}.png`)" class="image_8" />
                </div>
                <div style="opacity: 0;" class="flex-col items-start group_22">
                  <div class="section_10"></div>
                </div>
              </div>

            </div>

          </div>
          <div class="swiper-pagination"></div>
        </div>


        <div data-aos="fade-up" class="flex-col items-center section_11">
          <span class="text_44">轻装上阵 随心所驭</span>
          <span class="text_45">极致的轻量化设计助力赛道级操控</span>
          <div class="flex-row space-x-5 group_23 key-number-list" style="position: relative ;">


            <div class="key-number__bg">
              <div style="width: 100%;" class="key-number-sizer"></div>
            </div>

            <div class="key-number-sizer key-number-sizers">
              <div class="flex-col space-y-44 section_13 key-number">
                <span class="text_51">整体核心构建轻量化</span>
                <div class="mid-img-div">
                <img class="mid-img" src="@/assets/images/s7/119.png" alt="">
              </div>
                <span class="text_47">整车重量</span>
              </div>
            </div>
            <div class="key-number-sizer key-number-sizers">
              <div class="flex-col space-y-44 section_13 key-number">
                <span class="text_51">包含两颗</span>
                <div class="mid-img-div">
                <img class="mid-img" src="@/assets/images/s7/11.png" alt="">
              </div>
                <span class="text_47">动力锂电池</span>
              </div>
            </div>
            <div class="key-number-sizer key-number-sizers">
              <div class="flex-col items-center space-y-43 key-number section_13">
                <span class="text_54">轻量化助力赛道级操控</span>
                <div class="mid-img-div">
                <img class="mid-img" src="@/assets/images/s7/37.png" alt=""> </div>
                <div class="text_47">

                  <div  class="text_55">
                      对比同级燃油摩托车
                    
                    </div>
                    <div  class="text_56">车身重量减少37%</div>
                </div>
                
              </div>
            </div>


          </div>
        </div>
      </div>
      <div data-aos="fade-up" class="flex-col group_27">
        <div class="flex-col items-center space-y-24 group_28">
          <span class="text_58">完美的热管理系统</span>
          <span class="text_59">赛道级空气冷却设计</span>
        </div>
        <div class="flex-row space-x-60 group_29">
          <div class="flex-col items-center space-y-24 section_15">
            <span class="text_60">赛道级空气导流设计</span>
            <div class="group_30">
              <span class="text_61">
                将轻量化设计完美融入
                <br />
              </span>
              <span class="text_62">热管理系统的开发当中</span>
            </div>
          </div>
          <div class="flex-col items-center space-y-24 section_16">
            <span class="text_63">螺纹式散热鳍片</span>
            <div class="group_31">
              <span class="text_64">
                加之导风流道设计
                <br />
              </span>
              <span class="text_65">可以使空气更大量的通过马达表面</span>
            </div>
          </div>
        </div>
        <span class="text_66">极致操控 赢在细节</span>
        <div class="flex-col group_32">
          <div class="flex-row">
            <div class="image-wrapper_1">
            <div class="flex-col items-center image-wrapper_1 hover">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037063292061.png"
                class="image_10" />
            </div>
          </div>
            <div class="flex-col space-y-24 section_17">
              <span class="text_67">高抓力轮胎</span>
              <div class="group_34">
                <span class="text_68">
                  为极限驾驶的骑士打造的高性能轮胎，
                  <br />
                </span>
                <span class="text_69">
                  采用类全热熔的高抓地胶料和胎冠中沟的设计，
                  <br />
                </span>
                <span class="text_70">让你稳操胜券。</span>
              </div>
            </div>
          </div>
          <div class="flex-col">
            <div class="flex-row">
              <div class="flex-col items-center space-y-24 section_18">
                <span class="text_71">BOSCH ABS 10.1防锁死煞车系统</span>
                <div class="group_37 view_8">
                  <span class="text_73">
                    搭配ECU与轮速感知器，
                    <br />
                  </span>
                  <span class="text_75">
                    强震撼级制动规格三活塞卡钳，
                    <br />
                  </span>
                  <span class="text_77">即使电光一霎间的止步，也可绰有余暇。</span>
                </div>
              </div>
              <div class="image-wrapper_2">
              <div class="flex-col items-center image-wrapper_2 hover">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037063292061.png"
                  class="image_10" />
              </div>
            </div>
            </div>
            <div class="flex-row">
              <div class="image-wrapper_3">
              <div class="flex-col items-center image-wrapper_3 hover">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037063292061.png"
                  class="image_10" />
              </div>
            </div>
              <div class="flex-col items-center space-y-24 section_18">
                <span class="text_71">首创自牵引防滑系统</span>
                <div class="group_37">
                  <span class="text_73">
                    前后轮脉脉相通的自牵引力，
                    <br />
                  </span>
                  <span class="text_75">
                    给你制动时分的安全感，
                    <br />
                  </span>
                  <span class="text_77">施金石之计，行安全之路。</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-col space-y-24 section_19">
              <span class="text_83">全车LED汽车级灯具</span>
              <span class="text_84">
                Hazard智慧警示灯系统，以方向灯及紧急刹车警示灯为优先的智慧判断控制警示灯系统，EBS紧急刹车警示灯，紧急制动警示灯自动亮起，并快速闪烁，减少追撞几率。
              </span>
            </div>
            <div class="image-wrapper_4">
            <div class="flex-col items-center image-wrapper_4 hover">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037063292061.png"
                class="image_10" />
            </div>
          </div>
          </div>
        </div>
        
      </div>
      <div class="flex-col group_40">


        <constart car="s7"></constart>
        
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import constart from "../components/constart/index.vue"
export default {
  name: 'Vue3S7',
  components: { countTo, constart },
  data() {
    return {
      sindex: 0,
      sjarr: [
        {
          text: "雾海蓝",
          color: "#89bffa",
          url: require('@/assets/images/ionex/sj/1.png')
        },],
      list: [
        { title: "给够动力 就够给力", titles: "由智慧电控核心满擎驱动", pt: "VCU核心动力平台", des: "智能于心，更胜于形！", content: "具备强大的算法能力，<br>在动态中实时平衡各项动力输出" },
        { title: "给够动力 就够给力", titles: "由智慧电控核心满擎驱动", pt: "115V高电压平台", des: "高电压串联式电力结构", content: "全球街跑电摩领先性设计，采用三星21700-50ah<br>高性能锂电，115V高压电串联结构。<br>高效率，发热少，寿命长，支持更强动力输出和更<br>高速度，整车效能提升X倍。" },
        { title: "给够动力 就够给力", titles: "由智慧电控核心满擎驱动", pt: "赛跑级空冷电机", des: "11.2KG，同级电摩中重量最轻", content: "扭力两倍于125CC燃油车" },
      ],
      countshow: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1]
      },
    };
  },

  mounted() {

    var that = this;
    new Swiper('.swiper2', {
      pagination: {
        el: '.swiper-pagination',
        clickable :true,
      },
      loop: true,
    })
    setTimeout(() => {
      this.$nextTick(() => {
        
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var box1 = document.querySelector(".space-y-31");
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type == "attributes") {
              that.countshow = true;
            }
          });
        });
        
        observer.observe(box1, {
          attributes: true, //configure it to listen to attribute changes,
          attributeFilter: ['class']
        });

        var i = $(".key-number-list");
        var n = 0
          , s = !1;
        function e(t) {
          i.removeClass("active-1 active-2 active-3"),
            i.addClass("active-" + (t + 1))
        }
        setInterval((function () {
          s || e(n = (n + 1) % 3)
        }
        ), 5e3);
        $(".key-number-sizers").on("mouseenter", (function () {
          s = !0,
            e(n = i.find(".key-number-sizers").index($(this)))
        }
        )).on("mouseleave", (function () {
          s = !1
        }
        ))
        gsap.timeline({
          scrollTrigger: {
            trigger: ".text_19",
            scrub: .3,
            endTrigger: ".section_7",
            start: "30% bottom",
            end: "top 60%"
          }
        }).fromTo(".typography-leading-intro", {
          yPercent: 10,
          opacity: 0
        }, {
          yPercent: 0,
          opacity: 1
        })

        var t = gsap.timeline({
          scrollTrigger: {
            trigger: ".section_7",
            scrub: .3,
            start: "top 80%",
            end: "bottom bottom"
          }
        });
        t.fromTo(".section_7", {
          scale: .7,
          clipPath: "inset(15%)"
        }, {
          yPercent: 0,
          scale: 1,
          clipPath: "inset(0%)",
          duration: 1
        }),
          t.fromTo(".section_7", {
            opacity: 0
          }, {
            opacity: 1,
            duration: .5
          }, 0)
      });
    })
  },

  methods: {

  },
};
</script>
<style lang="scss">
.swiper2 {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 100px;
  }

  .swiper-pagination-bullet {
    background-color: #7d7d7d;
    width: 150px;
    height: 6px;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    background-color: #00b6ff;
  }
}
</style>
<style scoped>
  .group_32 .hover{
    transition: all .5s;
  }
  .group_32 .hover:hover {
    transform: scale(1.07);

  }
 
  .mid-img-div{
    height: 100.5px;
    display: flex;
    align-items: center;
  }
.key-number__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 34%;
  padding: 0 1px;
  transition: transform .5s;

}

.active-1 .key-number__bg {
  transform: translateX(0%)
}

.active-2 .key-number__bg {
  transform: translateX(100%)
}

.active-3 .key-number__bg {
  transform: translateX(200%)
}

.key-number__bg .key-number-sizer::before,
.key-number__bg .key-number-sizer::after {
  content: '';
  position: absolute;
  display: block
}

.key-number__bg .key-number-sizer::before {
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background: linear-gradient(225deg, #00b6ff, #005aff);
}

.key-number__bg .key-number-sizer::after {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background-color: #121215;
}

.swiper2 {
  width: 100%;

}

.page {
  background-color: #000000;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

}

.section_1 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494064579005367.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.section_2 {
  padding: 20px 100px;
  background-color: #00000126;
  position: relative;
}

.image {
  width: 55px;
  height: 30px;
}

.group_1 {
  margin: 4px 0;
}

.text {
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albb';
  line-height: 18px;
}

.text_1 {
  margin: 4px 0 3px 79px;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text_2 {
  margin: 4px 0 3px 72px;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text_3 {
  margin-left: 72px;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 19px;
}

.text_4 {
  margin: 4px 0 3px 78px;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text_5 {
  margin-left: 74px;
  margin-bottom: 3px;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Albbr';
  line-height: 18px;
}

.image_1 {
  margin-left: 77px;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
}

.image_2 {
  width: 6px;
  height: 6px;
  position: absolute;
  right: 918px;
  bottom: 12px;
}

.group_2 {
  margin-left: 241px;
  margin-top: 37vh;
  align-self: flex-start;
  width: 1034px;
}

.image_3 {
  margin-left: 49px;
  align-self: flex-start;
  width: 656px;
  height: 170px;
}

.group_3 {
  padding: 22px 0 17px;
  position: relative;
}

.image_4 {
  width: 727px;
  height: 85px;
}

.image-wrapper {
  padding: 9px 0 61px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494065470609677.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 908px;
  position: absolute;
  right: 0;
  top: 0;
}

.image_5 {
  width: 442px;
  height: 54px;
}

.group_4 {
 position: absolute;
 top: 100vh;
 transform: translateY(-100%);
 left: 0;
 right: 0;
}

.section_3 {
  padding: 25px 359px;
  background-color: #0e0e0e;
}

.text_6 {
  margin: 7px 0 6px;
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 27px;
}

.space-x-31>*:not(:first-child) {
  margin-left: 31px;
}

.text-wrapper {
  padding: 11px 0 10px;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  border: solid 2px #007aff;
}

.text_7 {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text-wrapper_1 {
  padding: 13px 0 12px;
  background-color: #007aff;
  border-radius: 20px;
  width: 140px;
  height: 40px;
}

.text_8 {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.section_4 {
  padding: 19px 356px 17px 360px;
  background-color: #3a3a3a;
}

.text_9 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.text_10 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.text_11 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.text_12 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.text_13 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.text_14 {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Albbr';
  line-height: 14px;
}

.section_5 {
 position: relative;

}

.space-y-75>*:not(:first-child) {
  margin-top: 75px;
}

.section_6 {
  padding: 272px 359px 418px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494064002371873.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_15 {
  color: #00b6ff;
  font-size: 48px;
  font-family: Adobe Heiti Std;
  line-height: 72px;
}

.text_16 {
  color: #ffffff;
  font-size: 48px;
  font-family: Adobe Heiti Std;
  line-height: 72px;
  white-space: nowrap;
}

.text_17 {
  color: #ffffff;
  font-size: 48px;
  font-family: Adobe Heiti Std;
  line-height: 72px;
}

.text_18 {
  color: #ffffff;
  font-size: 48px;
  font-family: Adobe Heiti Std;
  line-height: 72px;
}

.space-y-5>*:not(:first-child) {
  margin-top: 5px;
}

.group_7 {
  align-self: flex-start;
  width: 81px;
}

.text_19 {
  color: #00b6ff;
  font-size: 18px;
  line-height: 19px;
}

.divider {
  
  background-color: #00b6ff;
  height: 3px;
}

.space-y-42>*:not(:first-child) {
  margin-top: 42px;
}

.section_7 {
  padding: 783px 0 152px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494062765421156.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

}

.text_20 {
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 68px;
}

.space-x-27>*:not(:first-child) {
  margin-left: 27px;
}

.group_8 {
  width: 923px;
}

.text_21 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 34px;
}

.text_22 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 34px;
}

.text_23 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 34px;
}

.space-y-31>*:not(:first-child) {
  margin-top: 31px;
}

.section_8 {
  padding: 548px 102px 36px;
  align-self: center;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494062695822016.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1200px;
}

.group_10 {
  height: 44px;
}

.text_24 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 44px;
}

.text_26 {
  color: #ffffff;
  font-size: 36px;

  line-height: 21px;
}

.text_28 {
  color: #ffffff;
  font-size: 36px;

  line-height: 29px;
}

.group_11 {
  height: 43px;
}

.text_29 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 43px;
}

.text_30 {
  color: #ffffff;
  font-size: 36px;

  line-height: 28px;
}

.view_2 {
  margin-right: 3px;
}

.text_32 {
  color: #ffffff;
  font-size: 36px;

  line-height: 28px;
}

.space-x-186>*:not(:first-child) {
  margin-left: 186px;
}

.text_33 {
  color: #ffffff;
  font-size: 16px;

  line-height: 15px;
}

.text_34 {
  color: #ffffff;
  font-size: 16px;

  line-height: 15px;
}

.space-x-228>*:not(:first-child) {
  margin-left: 228px;
}

.group_14 {
  margin-right: 44px;
}

.text_35 {
  color: #ffffff;
  font-size: 16px;

  line-height: 15px;
}

.text_36 {
  color: #ffffff;
  font-size: 16px;

  line-height: 15px;
}

.section_9 {
  padding: 264px 360px 165px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494060397881898.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.space-y-24>*:not(:first-child) {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
 
  width: 100%;
  
}

.group_15 {
  align-self: flex-start;
  position: absolute;
  top: 160px;
  left: 0;
  padding: 0 36rem 0;
  z-index: 30;
}

.text_37 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
}

.text_38 {
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.group_16 {
  margin-top: 84px;
}

.space-y-49>*:not(:first-child) {
  margin-top: 49px;
}

.group_17 {
  margin-top: 50px;
}

.space-y-28>*:not(:first-child) {
  margin-top: 28px;
}

.text_39 {
  color: #00b6ff;
  font-size: 36px;

  font-weight: 700;
  line-height: 35px;
}

.text_40 {
  color: #ffffff;
  font-size: 23px;

  font-weight: 700;
  line-height: 22px;
}

.space-x-14>*:not(:first-child) {
  margin-left: 14px;
}

.group_20 {
  flex-shrink: 0;
}

.text_41 {
  align-self: flex-start;
  color: #ffffff;
  font-size: 18px;

}

.image_6 {
  margin-top: 34px;
  width: 260px;
  height: 8px;
}

.space-x-13>*:not(:first-child) {
  margin-left: 13px;
}

.group_21 {
  margin-top: 18px;
}

.image_7 {
  margin-bottom: 4px;
  flex-shrink: 0;
  width: 130px;
  height: 8px;
}

.text_42 {
  color: #ffffff;
  font-size: 14px;

  font-weight: 700;
  line-height: 14px;
}

.text_43 {
  margin-top: 52px;
  color: #ffffff;
  font-size: 14px;

  font-weight: 700;
  line-height: 11px;
}

.image_8 {
  width: 670px;
  height: 400px;
}

.group_22 {
  margin-top: 169px;
  align-self: center;
  width: 510px;
  border-left: solid 150px #00b6ff;
  border-right: solid 150px #7d7d7d;
}

.section_10 {
  margin-left: 180px;
  background-color: #7d7d7d;
  width: 150px;
  height: 6px;
}

.section_11 {
  padding: 0;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494058498235730.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.text_44 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
  margin-top: 165px;
}

.text_45 {
  margin-top: 24px;
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.space-x-5>*:not(:first-child) {
  margin-left: 5px;
}

.group_23 {
  margin-top: 54px;
  width: 760px;
}


.text_47{
  display: inline-block;
  height: 55px;
}
.text_47 *{
  text-align: center;
}
.section_12 {
  padding: 56px 28px 51px 32px;
  flex: 1 1 250px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494058813430334.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
}

.text_46 {
  margin-left: 6px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.group_24 {
  padding-top: 38px;
  position: relative;
}

.text-wrapper_2 {
  margin-right: 4px;
}

.view_4 {
  padding: 22px 0 6px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494058898663201.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 186px;
}

.text_47 {
  color: #ffffff;
  font-size: 16px;

  line-height: 16px;
}

.text_49 {
  color: #72fbfd;
  font-size: 60px;
  font-family: Alibaba;
  line-height: 43px;
  letter-spacing: -3px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.section_13::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000;
}

.section_13 {
  justify-content: center;
  align-items: center;
  flex: 1 1 250px;

  width: 100%;
  height: 250px;
  position: absolute;
  justify-content: space-between;
  padding-top: 30px;


}

.key-number-sizer {
  position: relative;
  padding-bottom: 100%;
  width: 33.3%;
}

.section_14 {
  position: relative;
  z-index: 10;
}

.text_51 {
  align-self: center;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.view_6 {
  padding: 22px 0 6px;
 
  width: 150px;
}



.section_14 {
  padding: 56px 0 31px;
  flex: 1 1 250px;
  background-color: #000000;
  width: 250px;
  height: 250px;
}

.text_54 {
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.group_25 {
  padding: 38px 0 20px;
  width: 146px;
  position: relative;
}

.image_9 {
  width: 121px;
  height: 44px;
}

.group_26 {

  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}

.text_55 {
  color: #ffffff;
  font-size: 16px;

  line-height: 1;
}

.text_56 {
  color: #ffffff;
  font-size: 16px;

  line-height: 1;
  margin-top: 10px;
}

.text_57 {
  color: #72fbfd;
  font-size: 60px;
  font-family: Alibaba;
  line-height: 43px;

  position: absolute;
  right: 10px;
  top: 0;
}

.group_27 {
  margin-top: 174px;
  align-self: center;
}

.group_28 {
  align-self: center;
}

.text_58 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
}

.text_59 {
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.space-x-60>*:not(:first-child) {
  margin-left: 60px;
}

.group_29 {
  margin-top: 58px;
}

.section_15 {
  padding: 405px 0 95px;
  flex: 1 1 570px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494057892630670.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 570px;
  height: 599px;
}

.text_60 {
  color: #00b6ff;
  font-size: 27.5px;

  font-weight: 700;
  line-height: 27px;
}

.group_30 {
  line-height: 24px;
  text-align: center;
}

.text_61 {
  color: #ffffff;
  font-size: 16px;

  line-height: 24px;
}

.text_62 {
  color: #ffffff;
  font-size: 16px;

  line-height: 24px;
}

.section_16 {
  padding: 406px 0 95px;
  flex: 1 1 570px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494057971315218.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 570px;
  height: 599px;
}

.text_63 {
  color: #00b6ff;
  font-size: 27.5px;

  font-weight: 700;
  line-height: 26px;
}

.group_31 {
  line-height: 24px;
  text-align: center;
}

.text_64 {
  color: #ffffff;
  font-size: 16px;

  line-height: 24px;
}

.text_65 {
  color: #ffffff;
  font-size: 16px;

  line-height: 24px;
}

.text_66 {
  margin-top: 317px;
  align-self: center;
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
}

.group_32 {
  margin-top: 74px;
}

.image-wrapper_1 {
  flex: 1 1 600px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037053176578.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 600px;
  height: 380px;
  overflow: hidden;
}

.image_10 {
  width: 600px;
  height: 380px;
}

.section_17 {
  padding: 127px 0 134px;
  flex: 1 1 600px;
  background-color: #1b1b1b;
  width: 600px;
  height: 380px;
}

.text_67 {
  align-self: center;
  color: #00b6ff;
  font-size: 24px;

  font-weight: 700;
  line-height: 23px;
}

.group_34 {
 text-align: center;
}

.text_68 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.text_69 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.text_70 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.section_18 {
  flex: 1 1 600px;
  padding: 127px 0 134px;
  background-color: #1b1b1b;
  width: 600px;
  height: 380px;
}

.text_71 {
  color: #00b6ff;
  font-size: 24px;

  font-weight: 700;
  line-height: 23px;
}

.group_37 {
 
  text-align: center;
}

.view_8 {
  width: 254px;
}

.text_73 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.text_75 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.text_77 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.image-wrapper_2 {
  flex: 1 1 600px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494052528566661.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 600px;
  height: 380px;
  overflow: hidden;
}

.image-wrapper_3 {
  flex: 1 1 600px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494052827409852.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 600px;
  height: 380px;
  overflow: hidden;
}

.section_19 {
  padding: 127px 85px 134px 87px;
  flex: 1 1 600px;
  background-color: #1b1b1b;
  width: 600px;
  height: 380px;
}

.text_83 {
  align-self: center;
  color: #00b6ff;
  font-size: 24px;

  font-weight: 700;
  line-height: 23px;
}

.text_84 {
  color: #ffffff;
  font-size: 14px;

  line-height: 24px;
}

.image-wrapper_4 {
  flex: 1 1 600px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494053834186869.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 600px;
  height: 380px;
  overflow: hidden;
}

.group_40 {
  margin-top: 163px;
}

.space-y-63>*:not(:first-child) {
  margin-top: 63px;
}

.section_20 {
  padding: 126px 0 83px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037052715160.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_85 {
  color: #ffffff;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.text_86 {
  margin: 3px 0;
  color: #ffffff;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.group_42 {
  width: 618px;
}

.group_43 {
  padding-top: 362px;
  position: relative;
}

.text-wrapper_3 {
  padding: 157px 0 38px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037052938238.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 618px;
}

.text_87 {
  color: #afbac4;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
}

.image_15 {
  width: 454px;
  height: 513px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.group_44 {
  margin-top: 5px;
}

.image_16 {
  width: 51px;
  height: 51px;
}

.image_17 {
  margin-left: 233px;
}

.image_18 {
  margin-right: 239px;
}

.text-wrapper_4 {
  margin-top: 31px;
  padding: 26px 0;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #ffffff;
}

.text_88 {
  color: #ffffff;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.image-wrapper_5 {
  padding: 179px 0 178px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16624494037025851462.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_19 {
  width: 1720px;
  height: 723px;
}

.space-y-48>*:not(:first-child) {
  margin-top: 48px;
}

.section_21 {
  padding: 90px 96px 140px 100px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316207594965.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_45 {
  padding-bottom: 31px;
  border-bottom: solid 1px #ffffff;
}

.image_20 {
  width: 191px;
  height: 31px;
}

.space-x-48>*:not(:first-child) {
  margin-left: 48px;
}

.group_46 {
  margin: 7px 0 6px;
}

.text_89 {
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 18px;
}

.text_90 {
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_91 {
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.space-y-15>*:not(:first-child) {
  margin-top: 15px;
}

.group_49 {
  width: 260px;
}

.image_21 {
  width: 260px;
  height: 22px;
}

.group_50 {
  line-height: 26px;
}

.text_92 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_93 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_94 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.image_22 {
  margin-right: 4px;
  width: 100px;
  height: 100px;
}

.text_95 {
  margin-top: 29px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 14px;

  line-height: 14px;
}

.group_51 {
  margin-top: 13px;
}

.group_52 {
  margin-top: 23px;
  line-height: 13px;
  height: 13px;
}

.text_96 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_97 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_98 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_99 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_100 {
  color: #ffffff;
  font-size: 12px;

  font-weight: 700;
  line-height: 10px;
}

.space-x-41>*:not(:first-child) {
  margin-left: 41px;
}

.group_53 {
  margin-right: 4px;
}

.image_23 {
  width: 36px;
  height: 36px;
}

.image_25 {
  width: 40px;
  height: 33px;
}

.image_26 {
  width: 41px;
  height: 34px;
}

.image_27 {
  width: 30px;
  height: 36px;
}

.image_29 {
  width: 39px;
  height: 36px;
}
</style>